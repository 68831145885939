// import { configureStore } from '@reduxjs/toolkit'
// import propertySlice from '../features/propertySlice'

// export const store = configureStore({
//     reducer: {
//         property: propertySlice,

//       },
      
// })



import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from "@reduxjs/toolkit";
import propertySlice from "../features/propertySlice";
// import { getDefaultMiddleware } from '@"redux-starter-kit';


const reducers = combineReducers({
  property: propertySlice,
  // middleware: getDefaultMiddleware({
  //      serializableCheck: {
  //       ignoredActions: ["persist/PERSIST"],
  //       },
  //     }),
});

const persistConfig = {
  key: "root",
  timeout: 2000, //Set the timeout function to 2 seconds
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
 
});


export default store;


